import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaCamera } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import "../../components/assets/css/slider.css";

import Header from "../../components/Header";
import { listCategory } from "../../functions/ProductDetails/Category";
import { listCategoryProducts } from "../../functions/ProductDetails/ProductCategory";
import {
  GetWhishlistUser,
  RemoveFromWhislist,
} from "../../functions/Prospect/Prsopect";
const WIshList = () => {
  const params = useParams();
  const ProductID = params.productid;
  const CategoryID = params.selectCategActive;

  const [categPro, setCategPro] = useState([]);
  const [categ, setCateg] = useState([]);

  const [WishlistList, setWishlistList] = useState([]);

  useEffect(() => {
    const userid = localStorage.getItem("RegisterUserID");

    GetWhishlistUser(userid)
      .then((res) => {
        console.log("get whishlist", res.data);
        setWishlistList(res.data);
      })
      .catch((err) => {
        console.log("error in getting whishlist");
      });
    // Fetch
  }, []);

  useEffect(() => {
    loadCategoryProducts();
    loadCategory();
  }, []);

  const loadCategoryProducts = () => {
    listCategoryProducts().then((res) => {
      console.log("RES IN CATEG PROD", res.data);
      setCategPro(res.data);
      const d = res.data;
      console.log("www", WishlistList);
      d.filter((product) =>
        WishlistList.some((wishlistItem) => wishlistItem === product._id)
      ).map((product) => console.log("ppp", product._id));
    });
  };
  const loadCategory = () => {
    listCategory().then((res) => {
      console.log("RES IN CATEG", res.data);
      setCateg(res.data);
    });
  };

  const hanldeDeletClick = (productid) => {
    const userid = localStorage.getItem("RegisterUserID");
    console.log("uu", userid);
    console.log("ppp", productid);
    RemoveFromWhislist({ userid, productid })
      .then((res) => {
        console.log("res in removeaddin", res.data);
        setWishlistList(res.data);
        // setWishListCheck(false);
      })
      .catch((err) => {
        console.log("error ina remove list", err);
      });
  };

  return (
    <React.Fragment>
      <Header />
      <div id="site-main" className="site-main">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div
              id="title"
              className="page-title page-title-dark title-who-we-are"
            >
              <div className="section-container text-start">
                <div className="content-title-heading">
                  <h1 className="text-title-heading">WISHLIST</h1>
                </div>
                <div className="breadcrumbs">
                  <Link to="/">Home</Link>
                  <span className="delimiter" />
                  WISHLIST
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <Row>
          {categPro && WishlistList.length !== 0 ? (
            <>
              {categPro
                .filter((product) =>
                  WishlistList.some(
                    (wishlistItem) => wishlistItem === product._id
                  )
                )
                .map((product) => (
                  <Col lg="4" key={product._id} style={{ marginTop: "15px" }}>
                    <Card
                      className="wishListCard"
                      style={{ width: "400px", height: "200px" }}
                    >
                      <div className="wishListDiv">
                        <div>
                          <Card style={{ width: "150px", height: "160px" }}>
                            <img
                              src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`}
                              alt={product.ProductName}
                            />
                          </Card>
                        </div>
                        <div>
                          <h1>{product.ProductWeight} g</h1>
                          <p style={{ marginBottom: "0px" }}>
                            {product.ProductDescription}
                          </p>

                          <div className="tryItDivMain">
                            <div className="tryAtBtn">
                              <Link
                                to="#"
                                className="button button-primary animation-horizontal"
                              >
                                TRY IT ON <FaCamera />
                              </Link>
                            </div>
                            <div
                              style={{ textAlign: "right", cursor: "pointer" }}
                              onClick={() => {
                                hanldeDeletClick(product._id);
                              }}
                            >
                              <MdDelete className="deleteIcon" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
            </>
          ) : (
            <>
              <div className="wishListDiv emptyWishlist">
                <h2>Your Wishlist Seems to be Empty!</h2>

                <div className="emptyWishlistBox">
                  <Link to="/the-collection">
                    <h2>Start Exploring</h2>
                  </Link>
                </div>
              </div>
            </>
          )}
        </Row>
      </Container>

      {/* <RangeSlider
        id="range-slider-gradient"
        step={"any"}
        defaultValue={[0, 50]}
        thumbsDisabled={[true, false]}
        rangeSlideDisabled={true}
      /> */}
      <Footer />
    </React.Fragment>
  );
};

export default WIshList;
