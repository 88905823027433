import React, { useState } from "react";
import Header from "../../components/Header";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "../../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Toast } from "react-bootstrap";
import { ChangePasswordParnter } from "../../functions/PartnerLogin";
const initialState = {
  password: "",
  newPassword: "",
  ConfirmPassword: "",
  userId: localStorage.getItem("PartnerID"),
};

const ChangePassword = () => {
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is a required field")
      .min(8, "Password must be at least 8 characters"),
  });
  const [values, setValues] = useState(initialState);
  const { password, newPassword, ConfirmPassword, userId } = values;

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [errP, setErrP] = useState(false);
  const [errNP, setErrNP] = useState(false);
  const [errCP, setErrCP] = useState(false);

  const validateValues = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (values.password === "") {
      errors.password = "Password is required!";
      setErrP(true);
    }
    if (values.password !== "") {
      setErrP(false);
    }

    if (values.newPassword === "") {
      errors.newPassword = "User Password is required!";
      setErrNP(true);
    }
    if (values.newPassword !== "") {
      setErrNP(false);
    }

    if (values.ConfirmPassword === "") {
      errors.ConfirmPassword = "Confirm Password is required!";
      setErrCP(true);
    }

    if (values.ConfirmPassword) {
      setErrCP(false);
    }

    //    DATE OF EXPIRY

    return errors;
  };

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const validClasspassword = errP ? "form-control is-invalid" : "form-control";
  const validClassNewPassword = errNP
    ? "form-control is-invalid"
    : "form-control";

  const validClassConfirmPassword = errCP
    ? "form-control is-invalid"
    : "form-control";

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("values", values);
    setIsSubmit(true);
    setFormErrors(validateValues(values));
    setValues({ ...values, userId: localStorage.getItem("PartnerID") });
    ChangePasswordParnter(values)
      .then((res) => {
        if (res.data.isOk) {
          console.log(" res updates", res);
          setValues(initialState);
          toast.success("Password Updated Successfully");
        } else {
          if (res.data.field === 1) {
            setErrCP(true);
            setFormErrors({ ConfirmPassword: res.data.message });
          } else if (res.data.field === 2) {
            setErrP(true);
            setFormErrors({ password: res.data.message });
          }
          console.log(" res updates", res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Header />
      <ToastContainer />
      <div id="site-main" className="site-main">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div
              id="title"
              className="page-title page-title-dark title-collection"
            >
              <div className="section-container text-start">
                <div className="content-title-heading">
                  <h1 className="text-title-heading">Change Password</h1>
                </div>
                <div className="breadcrumbs">
                  <Link to="/">Home</Link>
                  <span className="delimiter" />
                  Change Password
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <h1 className="myAcoountTitle">My Account</h1>
        <Row>
          <Col lg="3">
            <Link className="myAccountLinkDiv" to="/">
              <div className="myAccountLink">Overview</div>
            </Link>
            <Link to="/my-account-new">
              <div className="myAccountLink">Personal Information</div>
            </Link>
            {/* <Link to="/whishlistnew">
              <div className="myAccountLink">Wishlist</div>
            </Link>
            <Link to="/ziya-contact">
              <div className="myAccountLink">Inquire About Us</div>
            </Link> */}
            <Link to="/change-password">
              <div className="myAccountLink">Change Password</div>
            </Link>
          </Col>
          <Col lg="9">
            <h1>Change Password</h1>
            <Card className="changPasswordCard">
              {/* <Formik
                validationSchema={schema}
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => {
                  // Alert the input values of the form that we filled
                  alert(JSON.stringify(values));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => ( */}
              <div className="login">
                <div className="form">
                  <form
                  // noValidate onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col
                        className="changPasswordCol"
                        lg="6"
                        // style={{ marginTop: "20px", marginLeft: "10px" }}
                      >
                        <input
                          type="password"
                          name="password"
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          value={password}
                          placeholder="Enter old Password"
                          className={validClasspassword}
                          id="email"
                          style={{ height: "50px" }}
                        />
                        {/* <p className="error">
                          {errors.email && touched.email && errors.email}
                        </p> */}

                        {isSubmit && (
                          <p className="text-danger">{formErrors.password}</p>
                        )}
                      </Col>
                      <Col
                        // style={{ marginTop: "20px" }}
                        className="changPasswordCol"
                        lg="6"
                      >
                        <input
                          type="password"
                          name="newPassword"
                          onChange={handleChange}
                          value={newPassword}
                          placeholder="Enter New password"
                          className="form-control"
                          style={{ height: "50px" }}
                        />
                        {/* <p className="error">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p> */}
                        {isSubmit && (
                          <p className="text-danger">
                            {formErrors.newPassword}
                          </p>
                        )}
                      </Col>
                      <Col
                        // style={{ marginTop: "20px", marginLeft: "10px" }}
                        className="changPasswordCol"
                        lg="6"
                      >
                        <input
                          type="password"
                          name="ConfirmPassword"
                          onChange={handleChange}
                          value={ConfirmPassword}
                          placeholder="Enter Confirm Password"
                          className="form-control"
                          style={{ height: "50px" }}
                        />
                        {/* <p className="error">
                          {errors.password &&
                            touched.password &&
                            errors.password}
                        </p> */}
                        {isSubmit && (
                          <p className="text-danger">
                            {formErrors.ConfirmPassword}
                          </p>
                        )}
                      </Col>
                      <div className="intro-btn">
                        <Button
                          style={{ marginTop: "20px" }}
                          type="submit"
                          onClick={handleSubmit}
                          className="button button-outline animation-horizontal"
                        >
                          Submit Now
                        </Button>
                      </div>
                    </Row>
                  </form>
                </div>
              </div>
              {/* )}
              </Formik> */}
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default ChangePassword;
