import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import StoreLocater from "../../components/StoreLocator/StoreLocater";
import { listCategory } from "../../functions/ProductDetails/Category";
import PartnerLoginModal from "../modal/PartnerLogin";

const The_Collection = () => {
  const [activeTab, setActiveTab] = useState("bridal");
  const [showAllBridal, setShowAllBridal] = useState(false);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [colectionData, setColectionData] = useState([]);
  const [modalList, setModalList] = useState(false);

  useEffect(() => {
    loadCatgeories();
  }, []);

  const toggleShowAllBridal = () => {
    setShowAllBridal(!showAllBridal);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const loadCatgeories = () => {
    listCategory().then((res) => {
      setColectionData(res.data);
    });
  };

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const tog_list = (CollectID) => {
    // let collectionID = PID;
    // localStorage.setItem("ProductID", collectionID);
    const userid = localStorage.getItem("PartnerID");

    if (userid) {
      window.location.href = `/product-details/${CollectID}`;
    } else {
      // setModalList(!modalList);
      console.log("MODAL");
      setModalList(true);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#00BFFF"
            height={50}
            width={50}
            timeout={100} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Header />

          <div id="site-main" className="site-main">
            <div id="main-content" className="main-content">
              <div id="primary" className="content-area">
                <div
                  id="title"
                  className="page-title page-title-dark title-collection"
                >
                  <div className="section-container text-start">
                    <div className="content-title-heading">
                      <h1 className="text-title-heading">The Collection</h1>
                    </div>
                    <div className="breadcrumbs">
                      {/* <Link to="/">Home</Link>
                      <span className="delimiter" /> */}
                      The Collection
                    </div>
                  </div>
                </div>
                <div id="content" className="site-content" role="main">
                  <section className="section section-padding   p-t-70 p-b-50 m-b-70">
                    <div className="section-container small">
                      <div className="block block-intro layout-4">
                        <div className="block-widget-wrap">
                          <Row className="colectionRow">
                            <h1 className="colectionTitle">The Collection</h1>
                            {colectionData?.map(
                              (colection) =>
                                colection.isActive &&
                                colection.IsPartner && (
                                  <Col md="6" className="col-5-new">
                                    <Link
                                      // to={`/product-details/${colection._id}`}
                                      onClick={() => tog_list(colection._id)}
                                    >
                                      <div className="containerOne">
                                        <div class="section-column right">
                                          <img
                                            class="animation-horizontal"
                                            src={`${process.env.REACT_APP_API_URL_ZIYA}/${colection.CategoryImage}`}
                                            alt="intro"
                                          />
                                          <div className="text-block">
                                            <h3
                                              className="iconTitle"
                                              style={{ color: "#fff" }}
                                            >
                                              {colection.Category}
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </Col>
                                )
                            )}
                            <PartnerLoginModal
                              modalList={modalList}
                              setModalList={setModalList}
                            />{" "}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          {showBackToTopButton && (
            <div className="back-top button-show" onClick={scrollToTop}>
              <i className="arrow_carrot-up" />
            </div>
          )}
        </div>
      )}

      <StoreLocater />
    </div>
  );
};

export default The_Collection;
