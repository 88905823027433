import React, { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import Modal from "react-modal";
import { listCategoryProducts } from "../../functions/ProductDetails/ProductCategory";
import LoginModal from "./loginModal";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import popup from "../../assets/media/banner/1.jpg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ProductDetailsNewPopup({ isOpen, onClose }) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const [randomImage, setRandomImage] = useState(null);

  const [modalList, setModalList] = useState(false);

  const tog_list_Camera = () => {
    const userid = localStorage.getItem("RegisterUserID");
    if (!userid) {
      console.log("user found");
      setModalList(true);
    }
  };
  const [modalOpened, setModalOpened] = useState(false);

  useEffect(() => {
    const loadCategoryProducts = async () => {
      try {
        const response = await listCategoryProducts();
        console.log("respo", response.data);
        // const categoryProducts = response.data;
        const categoryProducts = response.data.filter(
          (product) =>
            product.isActive === true && product.IsTopProduct === false
        );
        if (categoryProducts && categoryProducts.length > 0) {
          // Choose a random image from the list
          const randomIndex = Math.floor(
            Math.random() * categoryProducts.length
          );
          setRandomImage(categoryProducts[randomIndex]?.ProductImage);
          // setRandomImage("uploads/CategoryProducts/1702556515853_20 (1).jpg");

          console.log("rr", randomIndex);
          console.log("IMAGE", categoryProducts[randomIndex]?.ProductImage);
        }
      } catch (error) {
        console.error("Error loading category products:", error);
      }
    };

    loadCategoryProducts();
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  // function closeModal() {
  //   setIsOpen(false);
  // }

  useEffect(() => {
    const alreadySubscribed = localStorage.getItem("subscribed");
    if (!alreadySubscribed) {
      setIsOpen(true);
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    localStorage.setItem("subscribed", "true");
  };

  // Reset the subscribed flag when the page is refreshed
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("subscribed");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      {randomImage ? (
        <Modal
          isOpen={isOpen}
          // onAfterOpen={afterOpenModal}
          onRequestClose={onClose}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Card style={{ padding: "10px" }}>
            <div
              className="croseIconDiv"
              style={{ cursor: "pointer" }}
              onClick={onClose}
            >
              <IoIosCloseCircle />
            </div>
            <div
              style={{ width: "350px" }}
              ref={(_subtitle) => (subtitle = _subtitle)}
            >
              <img
                style={{ border: "1px solid #210832" }}
                // src={Popup}
                src={`${process.env.REACT_APP_API_URL_ZIYA}/${randomImage}`}
                alt="img"
              />
            </div>
            <div className="popupTitle">
              <div className="title">This Design might look good on you</div>
              <div className="tryAtBtn" onClick={tog_list_Camera}>
                <Link
                  to="#"
                  className="button button-primary animation-horizontal tryAtOn"
                >
                  TRY ON <FaCamera />
                </Link>
              </div>
            </div>
            <LoginModal modalList={modalList} setModalList={setModalList} />
          </Card>
        </Modal>
      ) : null}
    </div>
  );
}

export default ProductDetailsNewPopup;
