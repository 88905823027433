import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Col, Input } from "reactstrap";
import * as Yup from "yup";
import Design from "../../components/media/banner/intro-2.jpg";
import { getUserByWP } from "../../functions/Prospect/Prsopect";
import SignUpModal from "./SignUp";
import { ToastContainer, toast } from "react-toastify";
import { Alert } from "react-bootstrap";

const LoginModal = ({ modalList, setModalList }) => {
  const schema = Yup.object().shape({
    mobileNumber: Yup.string()
      .required("mobile number is required ")
      .min(10, "mobile number must be at least 10 characters")
      .max(12, "mobile number must be at least 12 characters"),
  });
  // const [show, setShow] = useState(false);

  const tog_list = () => {
    setModalList(!modalList);
    // setValues(initialState);
  };
  const tog_listClose = () => {
    setModalList(false);
    console.log("ccc", modalList);
  };

  const [modalListSignup, setModalListSignUp] = useState(false);
  const [sucessMessage, setsucessMessage] = useState(false);

  const handleSignUpClick = () => {
    setModalList(false);
    console.log("s", modalList);
    setModalListSignUp(true);
    console.log("sss", modalListSignup);
  };

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [ContactNo, setContactNo] = useState("");

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("no errors");
    }
  }, [formErrors, isSubmit]);
  const handleChangeProspect = (e) => {
    setContactNo(e.target.value);
  };
  const [errCo, setErrCo] = useState(false);

  const validateProspect = (ContactNo) => {
    const errors = {};
    const phone = /^\d{10}$/;

    if (!ContactNo) {
      errors.ContactNo = "Contact No is required!";
      setErrCo(true);
    } else if (!phone.test(ContactNo)) {
      errors.ContactNo = "This is not a valid Phone Number";
      setErrCo(true);
    }
    if (ContactNo) {
      setErrCo(false);
    }

    return errors;
  };

  const validClassCNo =
    errCo && isSubmit ? "form-control is-invalid" : "form-control";

  const [showError, setShowError] = useState(false);

  const handleSubmitProspect = (e) => {
    e.preventDefault();

    // set_Id(_id);
    let erros = validateProspect(ContactNo);
    setFormErrors(erros);
    setIsSubmit(true);

    if (Object.keys(erros).length == 0) {
      getUserByWP(ContactNo)
        .then((res) => {
          console.log("log user res", res.data);
          if (res.data.isOk) {
            console.log(res);
            const userLog = res.data;
            console.log("dd", userLog.data);
            localStorage.setItem("RegisterUserID", userLog.data);
            setContactNo("");
            setsucessMessage(true);
            // navigate("/plan-pricing");
            setModalList(!modalList);
            // toast.success("Login Successfully");
            setShowError(true);
            // window.location.reload();

            setTimeout(() => {
              window.location.reload();
            }, 1000); // Adjust the time as needed
          } else {
            const user = res.data;
            if (user.field === 1) {
              setErrCo(true);
              setFormErrors({ ContactNo: user.message });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleClose = () => {
    setModalList(false);
  };

  return (
    <>
      {/* <Link onClick={() => tog_list()} className="menu-link">
        <span className="menu-item-text">Login</span>
      </Link> */}
      {showError && (
        /* <>
          <div
            style={{
              position: "fixed",
              top: "20px", // Adjust the top position as needed
              right: "20px", // Adjust the right position as needed
              zIndex: 1000, // Ensure it appears above other elements
              width: "250px",
              border: "30px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)", // Optional: Add a box shadow for a floating effect
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Alert
              // variant="danger"
              // onClose={() => setShowError(false)}
              // dismissible
              style={{
                borderRadius: "0", // Optional: Set border-radius to 0 for a sharp corner
                margin: "0", // Optional: Set margin to 0
                backgroundColor: "#E6E7FF", // Adjust the shade of purple as needed
                color: "black",
                // alignContent: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Login Successfull
            </Alert>
          </div>
        </> */

        <>
          <div
            className="alert-container"
            style={{
              position: "fixed",
              top: "20px", // Adjust the top position as needed
              right: "20px", // Adjust the right position as needed
              zIndex: 1000, // Ensure it appears above other elements
              width: "250px",
            }}
          >
            <Alert
              // variant="danger"
              // dismissible
              className="alert-display"
            >
              Login Successfully
            </Alert>
          </div>
        </>
      )}
      <ToastContainer />
      <Modal
        centered
        show={modalList}
        onHide={handleClose}
        toggle={() => {
          tog_list();
        }}
      >
        <div className="newsletter-popup">
          <a
            to="#"
            className="newsletter-close"
            onClick={() => {
              tog_listClose();
            }}
          />
          <div className="newsletter-container">
            <div className="newsletter-img">
              <img src={Design} alt />
            </div>
            <div className="newsletter-form">
              <div className="login">
                <div className="form">
                  <form>
                    <div style={{ marginBottom: "40px" }}>
                      <h4>Login</h4>
                    </div>

                    <Col lg={12}>
                      <div className="mb-3">
                        <label className="lableEmail">Contact Number</label>
                        <Input
                          style={{ borderColor: "black" }}
                          type="number"
                          className={validClassCNo}
                          id="companynamevalidation"
                          placeholder="Enter your Contact Number"
                          required
                          name="ContactNo"
                          value={ContactNo}
                          onChange={handleChangeProspect}
                        />
                        {/* <Label htmlFor="contactnumber" className="form-label">
                          Contact Number
                        </Label> */}

                        {isSubmit && (
                          <p className="text-danger">{formErrors.ContactNo}</p>
                        )}
                      </div>
                    </Col>

                    <p style={{ paddingBottom: "0px" }}>
                      Don’t have an account ?{" "}
                      <Link to="" onClick={handleSignUpClick}>
                        Sign Up
                      </Link>
                      {/* <span>
                        <SignUpModal onClick={handleClose} />
                      </span>{" "} */}
                    </p>
                    <button
                      type="submit"
                      className="button button-primary animation-horizontal"
                      onClick={handleSubmitProspect}
                    >
                      Sign In
                    </button>
                  </form>
                </div>
              </div>
              {/* ContactNo */}
            </div>
          </div>

          {sucessMessage && (
            <div className="wishlist-message-container">
              <div className="wishlist-message">
                <h4
                  style={{
                    color: "white",
                  }}
                >
                  Login Successfully
                </h4>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <SignUpModal
        modalListSignup={modalListSignup}
        setModalListSignUp={setModalListSignUp}
        // onClick={handleClose}
      />
    </>
  );
};

export default LoginModal;
