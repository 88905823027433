import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaCamera, FaRegHeart } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import LoginModal from "../modal/loginModal";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TrendingProducts from "../../components/TrendingProducts";
import { listCategory } from "../../functions/ProductDetails/Category";
import { listCategoryProducts } from "../../functions/ProductDetails/ProductCategory";
import ProcuctDetislPopup from "../modal/ProductDetialspPopUp";
import { IoIosHeart } from "react-icons/io";
import ProductDetailsNewPopup from "../modal/ProductDetialsNewPopup";
import {
  GetWhishlistUser,
  RemoveFromWhislist,
  AddToWhislist,
} from "../../functions/Prospect/Prsopect";
import { getTryJewelByProduct } from "../../functions/TryOn/TryJewel";
import TryCardModel from "../../components/TryOn/TryOnCardModel";
import TryonNew2 from "../../components/TryOn/TryonNew2";

const ProductDetailsNew = () => {
  const params = useParams();
  const ProductID = params.productid;
  const CategoryID = params.selectCategActive;

  const [isTry, setIsTry] = useState(false);
  const [tryProduct, setTryProduct] = useState("");
  const [tryJewel, setTryJewel] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [modalList, setModalList] = useState(false);

  useEffect(() => {
    loadTryJewel();
  }, [tryProduct]);

  const loadTryJewel = () => {
    getTryJewelByProduct(tryProduct._id).then((res) => {
      setTryJewel(res.data);
    });
  };

  const tog_list_Camera = (product) => {
    // const userid = localStorage.getItem("RegisterUserID");
    // if (!userid) {
    //   setModalList(true);
    //   setIsSubmit(false);
    //   setUserCheck(false);
    // } else {
    // setTryProduct(product);
    setTryProduct(product._id);
    getTryJewelByProduct(product._id).then((res) => {
      if (!res.data) {
        // setDisabledButton(true);
      } else {
        setTryJewel(res.data);
        setIsTry(!isTry);
      }
    });
    // }
  };

  // const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);

  // useEffect(() => {
  //   // Simulate a delay of 5 seconds (5000 milliseconds)
  //   const delay = 7000;
  //   const timeoutId = setTimeout(() => {
  //     setShowProductDetailsModal(true);
  //   }, delay);

  //   // Clear the timeout to prevent the modal from opening if the component unmounts
  //   return () => clearTimeout(timeoutId);
  // }, []);

  const [wishlistMessage, setWishlistMessage] = useState("");
  const [removeWishList, setRemoveWishList] = useState("");

  const OnWishListClick = (PID) => {
    console.log("pid", PID);
    let productid = PID;
    localStorage.setItem("ProductID from product details", PID);
    const userid = localStorage.getItem("RegisterUserID");

    if (userid) {
      console.log("user found");
      if (WishlistList.length === 0 || !WishlistList.includes(PID)) {
        console.log("in clicked on Checked whishlist");
        AddToWhislist({ userid, productid })
          .then((res) => {
            console.log("res in addin from product Details", res.data);
            setWishlistList(res.data);
            setWishlistMessage("Product added to wishlist!");

            setTimeout(() => {
              setWishlistMessage("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina adding list", err);
          });
      } else {
        console.log("in clicked on unChecked whishlist");
        RemoveFromWhislist({ userid, productid })
          .then((res) => {
            console.log("res in removeaddin product Details", res.data);
            setWishlistList(res.data);
            setRemoveWishList("Product removed from WishList!");
            setTimeout(() => {
              setRemoveWishList("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina remove list", err);
          });
      }
    } else {
      setModalList(true);
    }
  };

  // HANDLE POPU
  useEffect(() => {
    // Set showPopup to true after a delay (e.g., 5000 milliseconds or 5 seconds)
    const delay = 5000;
    const timeoutId = setTimeout(() => {
      setShowPopup(true);
    }, delay);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // const NextArrow = ({ onClick }) => (
  //   <div className="custom-arrow trend-next-arrow" onClick={onClick}>
  //     <AiOutlineRight />
  //   </div>
  // );

  // const PrevArrow = ({ onClick }) => (
  //   <div className="custom-arrow trend-prev-arrow" onClick={onClick}>
  //     <AiOutlineLeft />
  //   </div>
  // );

  const [WishlistList, setWishlistList] = useState([]);

  useEffect(() => {
    const userid = localStorage.getItem("RegisterUserID");

    GetWhishlistUser(userid)
      .then((res) => {
        console.log("get whishlist", res.data);
        setWishlistList(res.data);
      })
      .catch((err) => {
        console.log("error in getting whishlist");
      });
    // Fetch products based on the weight range
  }, []);

  const [categPro, setCategPro] = useState([]);
  const [categ, setCateg] = useState([]);

  useEffect(() => {
    loadCategoryProducts();
    loadCategory();
  }, []);

  const loadCategoryProducts = () => {
    listCategoryProducts().then((res) => {
      console.log("RES IN CATEG PROD", res.data);
      setCategPro(res.data);
    });
  };
  const loadCategory = () => {
    listCategory().then((res) => {
      console.log("RES IN CATEG", res.data);
      setCateg(res.data);
    });
  };

  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 4, // Display 4 products at a time
  //   slidesToScroll: 1,
  //   nextArrow: <NextArrow />,
  //   prevArrow: <PrevArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 769,
  //       settings: {
  //         slidesToShow: 3,
  //       },
  //     },
  //     {
  //       breakpoint: 525,
  //       settings: {
  //         slidesToShow: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 992,
  //       settings: {
  //         slidesToShow: 4,
  //       },
  //     },
  //   ],
  // };
  // const productData = [
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  //   {
  //     img: ProductImg,
  //   },
  // ];
  return (
    <React.Fragment>
      <Header />
      {/* <ProcuctDetislPopup /> */}
      <div id="site-main" className="site-main">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div
              id="title"
              className="page-title page-title-dark title-collection"
            >
              <div className="section-container text-start">
                {categ
                  .filter((c) => c._id === CategoryID)
                  .map((cp) => (
                    <React.Fragment key={cp._id}>
                      <div className="content-title-heading">
                        <h1 className="text-title-heading">{cp.Category}</h1>
                      </div>
                      <div className="breadcrumbs">
                        <Link to="/">The Collection</Link>
                        <span className="delimiter" />
                        {cp.Category}
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        {categPro
          .filter((c) => c._id === ProductID)
          .map((product) => (
            <Row>
              <Col lg="4">
                <Card className="productCard">
                  <img
                    src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`}
                    alt={product.ProductName}
                  />
                </Card>
              </Col>
              <Col lg="8" style={{ paddingLeft: "80px" }}>
                <div className="wishlistDiv">
                  <h1>Product Details</h1>
                  {/* <Link
                    to="#"
                    style={{
                      textAlign: "end",
                    }}
                    onClick={() => OnWishListClick(product._id)}
                  >
                    {WishlistList.includes(product._id) ? (
                      <IoIosHeart
                        style={{
                          color: "#e41717cf",
                          fontSize: "24px",
                        }}
                      />
                    ) : (
                      <FaRegHeart
                        style={{
                          fontSize: "22px",
                        }}
                      />
                    )}
                  </Link> */}
                  {/* {wishlistMessage && (
                    <div className="wishlist-message-container">
                      <div className="wishlist-message">
                        <h4
                          style={{
                            color: "white",
                          }}
                        >
                          {wishlistMessage}
                        </h4>
                      </div>
                    </div>
                  )} */}
                  {/* {removeWishList && (
                    <div className="wishlist-remove-container">
                      <div className="wishlist-remove-message">
                        <h4
                          style={{
                            color: "white",
                          }}
                        >
                          {removeWishList}
                        </h4>
                      </div>
                    </div>
                  )} */}
                </div>

                {/* {categPro
              .filter((c) => c._id === ProductID)
              .map((product) => ( */}
                <p>{product.ProductDescription}</p>
                {/* ))} */}

                {/* {categPro
              .filter((c) => c._id === ProductID)
              .map((product) => ( */}
                <Row>
                  {" "}
                  <Col lg="12">
                    <div key={product._id}>
                      {product.MetalDetails.map((metal, index) => (
                        <Card className="productCardSection">
                          <div key={index} className="metalDetailContainer">
                            <div>
                              <h3 className="metalName">{metal.MetalName}</h3>
                              <p className="metalWeight">
                                <b> {metal.MetalWeight} g</b>
                              </p>
                            </div>
                            <div className="metalDescription">
                              <p className="wightTitle">
                                {metal.MetalDescription}
                              </p>
                            </div>
                          </div>
                        </Card>
                      ))}
                    </div>
                  </Col>
                </Row>
                {/* ))} */}
                <Row>
                  <Col lg={6}>
                    <div className="tryAtBtn">
                      <button
                        to="#"
                        className="btn button button-primary animation-horizontal tryAtHome"
                        onClick={() => tog_list_Camera(product)}
                      >
                        TRY ON <FaCamera />
                      </button>
                      {/* <Link
                        to="#"
                        className="button button-primary animation-horizontal tryAtHome"
                      >
                        TRY ON
                        <FaCamera />
                      </Link> */}
                    </div>
                  </Col>
                </Row>
                <LoginModal modalList={modalList} setModalList={setModalList} />
                {isTry && (
                  <TryonNew2
                    isTry={isTry}
                    setIsTry={setIsTry}
                    tryJewel={tryJewel}
                    tryProductId={tryProduct}
                  />
                )}
              </Col>
            </Row>
          ))}

        {/* {showPopup && (
          <ProductDetailsNewPopup isOpen={true} onClose={handleClosePopup} />
        )} */}

        {/* {showProductDetailsModal && (
          <>
            <div style={{ zIndex: 9998 }} />
            <ProductDetailsNewPopup />
          </>
        )} */}
      </Container>
      {/* <div className="section section-padding background-img bg-img-3 p-t-60 p-b-70 m-b-70">
        <TrendingProducts />
      </div> */}
      <Footer />
    </React.Fragment>
  );
};

export default ProductDetailsNew;
