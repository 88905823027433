import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { IoMdHeart } from "react-icons/io";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { listGoldKarat } from "../functions/PriceTag/GoldKarat";
import { listLatestGoldPrice } from "../functions/PriceTag/GoldPrice";
import { listProspect } from "../functions/Prospect/Prsopect";
import PartnerLoginModal from "../pages/modal/PartnerLogin";
import LoginModal from "../pages/modal/loginModal";
import { listPartner } from "../functions/PartnerLogin";
const GoldPriceSection = () => {
  const [latestGoldPrice, setLatestGoldPrice] = useState("");
  const [latestGoldKarat, setLatestGoldKarat] = useState("");
  const [DisplayCheck, setDisplayCheck] = useState(false);

  const [isBlinking, setBlinking] = useState(true);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setBlinking((prevBlinking) => !prevBlinking);
    }, 500); // Adjust the interval based on your preference

    return () => {
      clearInterval(blinkInterval);
    };
  }, []);

  const loadGoldPrice = () => {
    listLatestGoldPrice().then((res) => {
      console.log("price", res.data);
      // if (res.data.IsDisplay) {
      //   setLatestGoldPrice(res.data.GoldPrice);
      // }
      setLatestGoldPrice(res.data.GoldPrice);
      setDisplayCheck(res.data.IsDisplay);
      listGoldKarat().then((res1) => {
        console.log("check res", res1);
        const re = res1.data;
        const goldk = re.find((r) => r._id === res.data.GoldCarat);
        setLatestGoldKarat(goldk.GoldKarat);
      });
      console.log("la", latestGoldPrice);
    });
  };
  useEffect(() => {
    loadGoldPrice();
  }, []);

  const [activeLink, setActiveLink] = useState(null);

  const [userData, setUserData] = useState("");
  const [modalList, setModalList] = useState(false);

  const handleHeartIconClick = () => {
    // Check if user is logged in
    if (userCheck) {
      // If user is logged in, open the LoginModal
      window.location.href = "/whishlistnew";
    } else {
      setModalList(true);
      // setOpenLogin(true);
    }
  };

  const [show, setShow] = useState(false);

  const tog_list = () => {
    setShow(!show);
    setModalList(true);
    // setValues(initialState);
  };

  const [userCheck, setuserCheck] = useState(false);
  let user = localStorage.getItem("PartnerID");

  useEffect(() => {
    loadGoldPrice();
    if (user) {
      setuserCheck(true);
      console.log("user found");
    }
  }, []);

  useEffect(() => {
    if (user) {
      setuserCheck(true);
      listPartner().then((res) => {
        console.log("partner data", res.data);
        console.log("USER", user);
        const d = res.data;
        const checkData = d.find((l) => l._id === user);
        setUserData(checkData.ContactName);
      });
    }
  }, [user]);

  const handleLogout = () => {
    localStorage.removeItem("PartnerID");
    window.location.reload();
  };

  const handleLinkClick = (index) => {
    setActiveLink(index);
  };

  return (
    <div>
      <div className="goldPriceDiv">
        <Row>
          <Col lg="9">
            {" "}
            {/* <span className="menu-item-text">
              {DisplayCheck ? (
                <div
                  className={`blinking-text ${
                    isBlinking ? "visible" : "hidden"
                  }`}
                >
                  <span className="goldPriceTitle"> Gold Price :- {""}</span>₹{" "}
                  {latestGoldPrice} (10gm/{latestGoldKarat})
                </div>
              ) : null}
            </span> */}
          </Col>
          <Col lg="3">
            <div className=" d-flex loginDiv">
              <div className="level-0 menu-item" data-page="ziya-contact">
                {/* <LoginModal /> */}
                <div className="dropdown">
                  <button className="dropbtn">
                    {" "}
                    <FaUser />
                  </button>

                  {userCheck ? (
                    <div
                      style={{ cursor: "pointer" }}
                      // onClick={handleLogout}
                      className="dropdown-content "
                    >
                      <div>
                        {" "}
                        <Link to="/my-account-new" className="menu-link">
                          {userData}
                        </Link>
                      </div>
                      <Link onClick={handleLogout} className="menu-link">
                        {" "}
                        Logout
                      </Link>
                    </div>
                  ) : (
                    /* <div className="dropdown-content">
                          <LoginModal />
                          <PartnerLoginModal />
                        </div> */
                    <div className="dropdown-content">
                      <Link onClick={() => tog_list()} className="menu-link">
                        <span className="menu-item-text"> Partner Login</span>
                      </Link>

                      <PartnerLoginModal
                        modalList={modalList}
                        setModalList={setModalList}
                      />
                    </div>
                  )}
                </div>
              </div>
              {/* <div className="level-0 menu-item" data-page="ziya-contact">
                    <Link
                      to={userCheck ? "/whishlistnew" : "/login"}
                      className="menu-link"
                    >
                      <span className="menu-item-text userIcon">
                        <FaRegHeart />
                      </span>
                    </Link>
                  </div> */}

              {/* wishlist
              <Link
                to="#"
                className="menu-link"
                onClick={() => handleHeartIconClick()}
              >
                <span className="menu-item-text userIcon">
                  <IoMdHeart />
                </span>
              </Link> */}
              {/* {openLogin && ( */}
              {/* <LoginModal modalList={modalList} setModalList={setModalList} /> */}
              {/* )} */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GoldPriceSection;
