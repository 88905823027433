import axios from "axios";

export const getPartnerLoginDetails = async (username, password) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/find-partner-details/${username}/${password}`
  );
};

export const listPartner = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/partners/ziya`
  );
};

export const getPartner = async (_id) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/get-partner/${_id}`
  );
};

export const updatePartner = async (_id, prospect) => {
  return await axios.put(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/partner/update-ziya/${_id}`,
    prospect
  );
};

export const ChangePasswordParnter = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/ziya-change-password`,
    values
  );
};
