import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { Col, Container, Row } from "reactstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import "../../components/assets/css/myAccount.css";
import { getPartner, updatePartner } from "../../functions/PartnerLogin";

// import { getProspect, updateProspect } from "../../functions/Prospect/Prsopect";
import moment from "moment";
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Input,
  Form,
  Label,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
const initialStateProspect = {
  ContactName: "",
  Email: "",
  UserName: "",
  IsActive: true,
};

const MyAccount = () => {
  // let user = "";

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [prospect, setProspect] = useState(initialStateProspect);
  const { ContactName, Email, UserName, IsActive } = prospect;

  const [openEdit, setOpenEdit] = useState(false);
  const handleEditClick = () => {
    setOpenEdit(true);
  };

  const tog_list = () => {
    setOpenEdit(!openEdit);
  };

  const [errCPN, setErrCPN] = useState(false);
  const [errPC, setErrPC] = useState(false);
  const [errCo, setErrCo] = useState(false);
  const [errPE, setErrPE] = useState(false);

  const validateProspect = (prospect) => {
    const errors = {};
    const company = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9]{2,}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phone = /^\d{10}$/;

    if (!prospect.ContactName) {
      errors.ContactName = "Contact Person Name is required!";
      setErrCPN(true);
    }
    if (prospect.ContactName) {
      setErrCPN(false);
    }

    if (!prospect.UserName) {
      errors.UserName = "User Name is required!";
      setErrPC(true);
    }
    if (prospect.UserName) {
      setErrPC(false);
    }

    // if (!prospect.ContactNo) {
    //   errors.ContactNo = "Contact No is required!";
    //   setErrCo(true);
    // } else if (!phone.test(prospect.ContactNo)) {
    //   errors.ContactNo = "This is not a valid Phone Number";
    //   setErrCo(true);
    // }
    // if (prospect.ContactNo) {
    //   setErrCo(false);
    // }
    if (!prospect.EmailID) {
      errors.EmailID = "Email is required!";
      setErrPE(true);
    } else if (!regex.test(prospect.EmailID)) {
      errors.EmailID = "This is not a valid email format!";
      setErrPE(true);
    }
    if (prospect.EmailID) {
      setErrPE(false);
    }

    //    DATE OF EXPIRY

    return errors;
  };

  const handleChangeProspect = (e) => {
    setProspect({ ...prospect, [e.target.name]: e.target.value });
  };

  const validClassProspectPersonName =
    errCPN && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassProspectEmailID =
    errPE && isSubmit ? "form-control is-invalid" : "form-control";

  const validClassUN =
    errPC && isSubmit ? "form-control is-invalid" : "form-control";

  let user = localStorage.getItem("PartnerID");

  useEffect(() => {
    // let user = localStorage.getItem("RegisterUserID");
    console.log("uuu", user);
    const _id = user;
    getPartner(_id)
      .then((res) => {
        console.log("res in resp", res);

        setProspect({
          ...prospect,
          ContactName: res.data.ContactName,
          Email: res.data.Email,
          UserName: res.data.UserName,
        });
      })
      .catch((err) => {
        console.log("error in getting", err);
      });
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();
    console.log("values", prospect);
    setFormErrors(validateProspect(prospect));
    updatePartner(user, prospect)
      .then((res) => {
        console.log(" res updates", res);
        setOpenEdit(!openEdit);
        // setValues([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCancel = () => {
    setOpenEdit(false);
  };

  return (
    <>
      <div>
        <Header />
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div
                id="title"
                className="page-title page-title-dark title-collection"
              >
                <div className="section-container text-start">
                  <div className="content-title-heading">
                    <h1 className="text-title-heading">My Account</h1>
                  </div>
                  <div className="breadcrumbs">
                    <Link to="/">Home</Link>
                    <span className="delimiter" />
                    My Account
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <Container>
            <h1 className="myAcoountTitle">My Account</h1>
            <Row>
              <Col lg="3">
                <Link className="myAccountLinkDiv" to="/">
                  <div className="myAccountLink">Overview</div>
                </Link>
                <Link to="/my-account-new">
                  <div className="myAccountLink">Personal Information</div>
                </Link>
                {/* <Link to="/whishlistnew">
                  <div className="myAccountLink">Wishlist</div>
                </Link> */}
                {/* <Link to="/ziya-contact">
                  <div className="myAccountLink">Inquire About Us</div>
                </Link> */}
                <Link to="/change-password">
                  <div className="myAccountLink">Change Password</div>
                </Link>
              </Col>
              <Col lg="9">
                <div>
                  <h1>Account Overview</h1>
                  <div className="detailsDiv">
                    <div className="informationDiv">
                      <h3 className="informationTitle">Personal Information</h3>
                      <div>
                        <button className="editBtn" onClick={handleEditClick}>
                          Edit Details
                        </button>
                      </div>
                    </div>
                    <Row className="myAccountRow">
                      <Col lg="3">
                        <h4>Name:</h4>
                      </Col>
                      <Col lg="9">
                        <h4>{ContactName}</h4>
                      </Col>

                      <Col lg="3">
                        <h4>User Name:</h4>
                      </Col>
                      <Col lg="9">
                        <h4>{UserName}</h4>
                      </Col>

                      <Col lg="3">
                        <h4>Email address:</h4>
                      </Col>
                      <Col lg="9">
                        <h4>{Email}</h4>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>

      {/* EDIT DETAILS */}
      <Modal
        isOpen={openEdit}
        toggle={() => {
          tog_list();
        }}
        style={{
          width: "600px",
          height: "600px",
        }}
        centered
        // style={{ backgroundColor: "white" }} // Add this line
      >
        <ModalHeader
          className=" p-3"
          toggle={() => {
            tog_list();
          }}
          style={{ height: "50px" }}
        >
          <h3 style={{ paddingTop: "25px", paddingBottom: "15px" }}>
            Edit Detials
          </h3>
        </ModalHeader>

        <ModalBody style={{ width: "700px", height: "500px" }}>
          <Row>
            <Col lg={6}>
              {/* Contact Person Name  */}

              <div className="mb-3">
                <Label className="form-label">
                  Contact Name
                  {/* <span className="text-danger">*</span> */}
                </Label>
                <Input
                  type="text"
                  className={validClassProspectPersonName}
                  id="contactpersonnamevalidation"
                  placeholder="Enter Contact Person Name"
                  required
                  name="ContactName"
                  value={ContactName}
                  onChange={handleChangeProspect}
                  style={{ height: "50px" }}
                />

                {isSubmit && (
                  <p className="text-danger">{formErrors.ContactName}</p>
                )}
              </div>
            </Col>

            <Col lg={6}>
              {/* ContactNo */}
              <div className="mb-3">
                <Label htmlFor="contactnumber" className="form-label">
                  User Name
                  {/* <span className="text-danger">*</span> */}
                </Label>
                <Input
                  type="text"
                  className={validClassUN}
                  id="companynamevalidation"
                  placeholder="Enter your Contact Number"
                  required
                  name="UserName"
                  value={UserName}
                  onChange={handleChangeProspect}
                  style={{ height: "50px" }}
                />

                {isSubmit && (
                  <p className="text-danger">{formErrors.UserName}</p>
                )}
              </div>
            </Col>
          </Row>

          <Col lg={7}>
            {/* EmailID */}
            <div className="mb-3">
              <Label htmlFor="email" className="form-label">
                Email ID
                {/* <span className="text-danger">*</span> */}
              </Label>
              <Input
                type="email"
                className={validClassProspectEmailID}
                id="companynamevalidation"
                placeholder="Enter your Email ID"
                required
                name="Email"
                value={Email}
                onChange={handleChangeProspect}
                style={{ height: "50px" }}
              />

              {isSubmit && <p className="text-danger">{formErrors.Email}</p>}
            </div>
          </Col>
        </ModalBody>
        <ModalFooter>
          <>
            <div className="text-end">
              <button
                style={{ width: "70px", height: "40px", fontSize: "large" }}
                onClick={handleUpdate}
                className="btn btn-success  m-1"
              >
                Save
              </button>
              <button
                style={{ width: "80px", height: "40px", fontSize: "large" }}
                className="btn btn-outline-danger m-1"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </>
          {/* </div> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MyAccount;
