import React from "react";
import "react-range-slider-input/dist/style.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/components/assets/css/app.css";
import "./App.css";
// import "react-range-slider-input/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";

import "./components/assets/css/app.css";
import "./components/assets/css/picbox.css";
import "./components/assets/css/responsive.css";
import "./components/libs/elegant-icons/css/elegant.css";
import "./components/libs/feather-font/css/iconfont.css";
import "./components/libs/font-awesome/css/font-awesome.css";
import "./components/libs/icomoon-font/css/icomoon.css";
import "./components/libs/wpbingofont/css/wpbingofont.css";
import SignUpIndex from "./pages/CustomerSignUp/SignIndex";
import ProductDetails from "./pages/ProductDetials/ProductDetails";
import ProductDetailsNew from "./pages/ProductDetials/ProductDetailsNew";
import WIshList from "./pages/WishList/WIshList";
import The_Collection from "./pages/collection/The_Collection";
// import Find_My_Ziya from "./pages/find_my_ziya/Find_My_Ziya";
// import HomePage from "./pages/home/HomePage";
import MyAccount from "./pages/my_account/MyAccount";
import ChangePassword from "./pages/my_account/ChangPassword";
// import Who_We_Are from "./pages/who_we_are/Who_We_Are";
// import Ziya_Contact from "./pages/ziya_contact/Ziya_Contact";
// import Ziya_Experience from "./pages/ziya_experience/Ziya_Experience";
function App() {
  return (
    <div className="">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<HomePage />} /> */}
          {/* <Route path="/who-we-are" element={<Who_We_Are />} /> */}
          {/* <Route path="/ziya-experience" element={<Ziya_Experience />} /> */}
          <Route path="/" element={<The_Collection />} />
          {/* <Route path="/find-my-ziya" element={<Find_My_Ziya />} /> */}
          {/* <Route path="/ziya-contact" element={<Ziya_Contact />} /> */}
          <Route path="/product-new" element={<ProductDetailsNew />} />
          <Route path="/whishlistnew" element={<WIshList />} />
          <Route path="/login-user" element={<SignUpIndex />} />

          <Route
            path="/product-new/:productid/:selectCategActive"
            element={<ProductDetailsNew />}
          />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/wish-list" element={<WIshList />} />
          <Route path="/my-account-new" element={<MyAccount />} />
          <Route path="/change-password" element={<ChangePassword />} />

          <Route
            path="/product-details/:category"
            element={<ProductDetails />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
