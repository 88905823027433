import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaBullseye, FaCamera, FaRegHeart } from "react-icons/fa";
import { IoIosHeart } from "react-icons/io";

import { Link, useParams } from "react-router-dom";
import gallery1 from "../../assets/media/gallery/gallery1.jpg";
import gallery2 from "../../assets/media/gallery/gallery2.jpg";
import gallery3 from "../../assets/media/gallery/gallery3.jpg";
import gallery4 from "../../assets/media/gallery/gallery4.jpg";
import gallery5 from "../../assets/media/gallery/gallery5.jpg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import StoreLocater from "../../components/StoreLocator/StoreLocater";
import { listCategory } from "../../functions/ProductDetails/Category";
import {
  FilterProductByWeight,
  listCategoryProducts,
} from "../../functions/ProductDetails/ProductCategory";

import {
  AddToWhislist,
  GetWhishlistUser,
  RemoveFromWhislist,
} from "../../functions/Prospect/Prsopect";
import LoginModal from "../modal/loginModal";
import TryCardModel from "../../components/TryOn/TryOnCardModel";
import { getTryJewelByProduct } from "../../functions/TryOn/TryJewel";
import PartnerLoginModal from "../modal/PartnerLogin";
import TryonNew2 from "../../components/TryOn/TryonNew2";
const ProductDetails = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [lisCateg, setLisCateg] = useState([]);
  const [lisCategProd, setLisCategProd] = useState([]);
  const [lisCategActiveItemID, setLisCategActiveItemID] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Amore"); // Initialize it with a default value
  const [selectCategActive, setselectCategActive] = useState(""); // Initialize it with a default value
  const [descr, setDescrip] = useState(""); // Initialize it with a default value
  const [descrActi, setDescripActiv] = useState(""); // Initialize it with a default value
  const [activeCheck, setActiveCheck] = useState(false);
  const [LoadCheck, setLoadCheck] = useState(false);

  useEffect(() => {
    setLoadCheck(true);
  }, []);

  const { category } = useParams();

  useEffect(() => {
    // loadCatgeories();
    listCategory().then((res) => {
      setActiveCheck(false);
      setselectCategActive(category);
      const foundCategory = res.data.find((c) => c._id == category);
      setSelectedCategory(foundCategory.Category);
      setDescrip(foundCategory.Description);
      // setLisCategActiveItem(foundCategory.Category);
      setLisCategActiveItemID(foundCategory._id);
    });
  }, [category]);

  const openModal = () => {
    setModalOpen(true);
  };
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // CUSTOMERSIGNUP

  const [modalList, setModalList] = useState(false);

  const [selectedLayoutId, setSelectedLayoutId] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [WishlistCheck, setWishListCheck] = useState(false);
  const [WishlistList, setWishlistList] = useState([]);
  const [UserCheck, setUserCheck] = useState(false);

  // WEIGHT FILTER
  const [weightRange, setWeightRange] = useState([0, 100]);

  const [isTry, setIsTry] = useState(false);
  const [tryProduct, setTryProduct] = useState("");
  const [tryJewel, setTryJewel] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    const userid = localStorage.getItem("PartnerID");

    GetWhishlistUser(userid)
      .then((res) => {
        setWishlistList(res.data);
      })
      .catch((err) => {
        console.log("error in getting whishlist");
      });
    // Fetch products based on the weight range
  }, []);

  useEffect(() => {
    loadTryJewel();
  }, [tryProduct]);

  const loadTryJewel = () => {
    getTryJewelByProduct(tryProduct._id).then((res) => {
      setTryJewel(res.data);
    });
  };

  const [wishlistMessage, setWishlistMessage] = useState("");
  const [removeWishList, setRemoveWishList] = useState("");

  const tog_list_Camera = (product) => {
    const userid = localStorage.getItem("PartnerID");
    if (!userid) {
      setModalList(true);
      setIsSubmit(false);
      setUserCheck(false);
    } else {
      // setTryProduct(product);
      setTryProduct(product._id);
      getTryJewelByProduct(product._id).then((res) => {
        if (!res.data) {
          // setDisabledButton(true);
        } else {
          setTryJewel(res.data);
          setIsTry(!isTry);
        }
      });
    }
  };

  const tog_list = (PID) => {
    let productid = PID;
    localStorage.setItem("ProductID", PID);
    const userid = localStorage.getItem("PartnerID");

    if (userid) {
      if (WishlistList.length === 0 || !WishlistList.includes(PID)) {
        AddToWhislist({ userid, productid })
          .then((res) => {
            setWishlistList(res.data);
            setWishListCheck(true);
            setWishlistMessage("Product added to wishlist!");

            setTimeout(() => {
              setWishlistMessage("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina adding list", err);
          });
      } else {
        RemoveFromWhislist({ userid, productid })
          .then((res) => {
            setWishlistList(res.data);
            setWishListCheck(false);
            setRemoveWishList("Product removed from wishlist!");
            setTimeout(() => {
              setRemoveWishList("");
            }, 1500);
          })
          .catch((err) => {
            console.log("error ina remove list", err);
          });
      }
    } else {
      // setModalList(!modalList);
      setModalList(true);
      setIsSubmit(false);
      setUserCheck(true);
    }

    setSelectedLayoutId(PID); // Assuming you have a state to store the selected layoutId
  };

  useEffect(() => {
    if (UserCheck) {
      setModalList(true);
    }
  }, [UserCheck]);

  const showSlide = (slideNumber) => {
    setCurrentSlide(slideNumber);
  };
  const nextSlide = () => {
    setCurrentSlide((currentSlide % 5) + 1);
  };

  useEffect(() => {
    loadCatgeories();
    loadCatgeoryproducts();
    setActiveCheck(true);
  }, []);

  const loadCatgeories = () => {
    listCategory().then((res) => {
      setLisCateg(res.data);
    });
  };

  const loadCatgeoryproducts = () => {
    listCategoryProducts().then((res) => {
      setLisCategProd(res.data);
    });
  };

  const handleChangeWeight = (e) => {
    setWeightRange([0, parseInt(e.target.value)]);
    FilterProductByWeight(weightRange[0], weightRange[1])
      .then((res) => {
        console.log("res in filter", res.data);
      })
      .catch((err) => {
        console.log("error in filter", err);
      });
  };

  const handleCategorySelection = (category) => {
    setActiveCheck(false);
    setselectCategActive(category);
    const foundCategory = lisCateg.find((c) => c._id == category);
    setSelectedCategory(foundCategory.Category);
    setDescrip(foundCategory.Description);
    setLisCategActiveItemID(foundCategory._id);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 1 ? 5 : currentSlide - 1);
  };

  const galleryImages = [gallery1, gallery2, gallery3, gallery4, gallery5];
  return (
    <React.Fragment>
      <Header />

      <div id="site-main" className="site-main">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div
              id="title"
              className="page-title page-title-dark title-collection"
            >
              <div className="section-container text-start">
                <div className="content-title-heading">
                  <h1 className="text-title-heading">{selectedCategory}</h1>
                </div>
                <div className="breadcrumbs">
                  {/* <Link to="/">Home</Link> */}
                  {/* <span className="delimiter" /> */}
                  <Link to="/">The Collection</Link>
                  <span className="delimiter" />
                  {selectedCategory}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="site-main" className="site-main">
          <div id="main-content" className="main-content">
            <div id="primary" className="content-area">
              <div id="content" className="site-content" role="main">
                <div className="section-padding">
                  <Container>
                    <Row>
                      <Col className="col-lg-3 col-md-12 col-12 sidebar left-sidebar blog-details-sidebar">
                        {/* Block Post Categories */}

                        <div className="block block-post-tags">
                          <div className="block-title">
                            <h2 className="categroyTitle">Categories</h2>
                          </div>
                          <div className="block-content">
                            <div className="post-tags-list">
                              <ul className="categoryUl">
                                {lisCateg.map((l, index) =>
                                  l.isActive && l.IsPartner ? (
                                    <React.Fragment key={l._id}>
                                      <li
                                        value={l._id}
                                        onClick={() =>
                                          handleCategorySelection(l._id)
                                        }
                                        className={
                                          l._id === lisCategActiveItemID
                                            ? "activeCategory"
                                            : ""
                                        }
                                      >
                                        <button
                                          className="btn btn-lg product-button"
                                          style={{
                                            background: "none",
                                            border: "none",
                                            padding: "7px",
                                            font: "inherit",
                                            width: "100%",
                                            textAlign: "start",
                                            textDecoration: "none",
                                            color:
                                              l._id === lisCategActiveItemID &&
                                              "white",
                                            cursor: "pointer",
                                          }}
                                        >
                                          {l.Category}
                                        </button>
                                      </li>
                                    </React.Fragment>
                                  ) : null
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col className=" col-lg-9 col-md-12 col-12 md-b-30 blog-details-content">
                        <div className="post-details">
                          <h2 className="productDetailsTitle">
                            {selectedCategory}
                          </h2>
                          <div className="post-content clearfix">
                            {activeCheck ? (
                              <p className="productDesription">{descrActi}</p>
                            ) : (
                              <p className="productDesription">{descr}</p>
                            )}

                            <div>
                              <div className="posts-list grid">
                                <Row>
                                  {activeCheck
                                    ? lisCategProd
                                        .filter(
                                          (product) =>
                                            product.Category ===
                                            lisCategActiveItemID
                                        )
                                        .map(
                                          (product, index) =>
                                            product.isActive &&
                                            product.isPartner && (
                                              <Col
                                                lg="3"
                                                md="6"
                                                sm="12"
                                                key={index}
                                              >
                                                <div className="post-entry clearfix post-wapper">
                                                  <div className="post-image">
                                                    <div
                                                      // href="/customer-signup"
                                                      onClick={() =>
                                                        tog_list(product._id)
                                                      }
                                                    >
                                                      <img
                                                        src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`}
                                                        onClick={() => {
                                                          openModal();
                                                          showSlide(index + 1);
                                                        }}
                                                        className="hover-shadow cursor"
                                                        alt={
                                                          product.ProductName
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </Col>
                                            )
                                        )
                                    : lisCategProd
                                        .filter(
                                          (product) =>
                                            product.Category ===
                                            selectCategActive
                                        )
                                        .map(
                                          (product, index) =>
                                            product.isActive &&
                                            product.isPartner && (
                                              <Col
                                                lg="3"
                                                md="6"
                                                sm="12"
                                                key={index}
                                              >
                                                <div className="post-entry clearfix post-wapper">
                                                  <div className="post-image">
                                                    <div
                                                      // to={`/product-new/${product._id}/${selectCategActive}`}
                                                      to="#"
                                                    >
                                                      <Card className="hover-shadow  theColectionImg">
                                                        {/* <Link
                                                          to="#"
                                                          style={{
                                                            textAlign: "end",
                                                          }}
                                                          onClick={() =>
                                                            tog_list(
                                                              product._id
                                                            )
                                                          }
                                                        >
                                                          {WishlistList.includes(
                                                            product._id
                                                          ) ? (
                                                            <IoIosHeart
                                                              style={{
                                                                color:
                                                                  "#e41717cf",
                                                                fontSize:
                                                                  "24px",
                                                              }}
                                                            />
                                                          ) : (
                                                            <FaRegHeart
                                                              style={{
                                                                fontSize:
                                                                  "22px",
                                                              }}
                                                            />
                                                          )}
                                                        </Link> */}

                                                        <img
                                                          src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`}
                                                          onClick={() => {
                                                            openModal();
                                                            showSlide(
                                                              index + 1
                                                            );
                                                          }}
                                                          alt={
                                                            product.ProductName
                                                          }
                                                        />
                                                        <p>
                                                          {/* <b>
                                                            {
                                                              product.ProductWeight
                                                            }
                                                            g
                                                          </b> */}
                                                        </p>
                                                      </Card>
                                                    </div>

                                                    <div className="tryAtBtn">
                                                      <button
                                                        to="#"
                                                        className="btn button button-primary animation-horizontal"
                                                        onClick={() =>
                                                          tog_list_Camera(
                                                            product
                                                          )
                                                        }
                                                      >
                                                        TRY ON <FaCamera />
                                                      </button>
                                                    </div>

                                                    {/* {wishlistMessage && (
                                                      <div className="wishlist-message-container">
                                                        <div className="wishlist-message">
                                                          <h4
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {wishlistMessage}
                                                          </h4>
                                                        </div>
                                                      </div>
                                                    )} */}

                                                    {/* {removeWishList && (
                                                      <div className="wishlist-remove-container">
                                                        <div className="wishlist-remove-message">
                                                          <h4
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {removeWishList}
                                                          </h4>
                                                        </div>
                                                      </div>
                                                    )} */}
                                                  </div>
                                                </div>
                                              </Col>
                                            )
                                        )}
                                </Row>
                                {/* <SignUpIndex
                                  modalList={modalList}
                                  setModalList={setModalList}
                                /> */}
                                <PartnerLoginModal
                                  modalList={modalList}
                                  setModalList={setModalList}
                                />
                                {isTry && (
                                  <TryonNew2
                                    isTry={isTry}
                                    setIsTry={setIsTry}
                                    tryJewel={tryJewel}
                                    tryProductId={tryProduct}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              {/* #content */}
            </div>
            {/* #primary */}
          </div>
          {/* #main-content */}
        </div>
      </div>

      {showBackToTopButton && (
        <div className="back-top button-show" onClick={scrollToTop}>
          <i className="arrow_carrot-up" />
        </div>
      )}
      <Footer />
      <StoreLocater />
    </React.Fragment>
  );
};

export default ProductDetails;
